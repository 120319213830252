<template>
  <div class="viewcontent">
    <h2 v-if="calldata && calldata.hostguest_fullname">
      {{ calldata.hostguest_fullname }}
      <button
        class="primary_color"
        @click="showCard()"
        v-if="calldata.hostguest_code"
      >
        <i :class="'far fa-address-card fa-1x'"></i>
      </button>
    </h2>
    <Card :data="card" :style="0" v-if="card && show" />

    <div v-if="checkTime == 1">
      {{ translations.time_meeting_in }}
      <Timer
        :endDate="infoMessages[0].endTime"
        v-if="infoMessages[0]"
        @endTime="endTime()"
      />
    </div>
    <div v-else-if="checkTime == 2">
      {{
        replaceTranslation({
          type: 'end',
          var: calldata.day + ' ' + calldata.time_end,
        })
      }}
    </div>
    <div v-else-if="checkTime == 0">
      {{ replaceTranslation({ type: 'next', text: calldata.time_start }) }}
    </div>
    <div class="ma-2" v-if="checkTime == 1">
      <div id="meet"></div>
    </div>
  </div>
</template>

<script>
  //https://stackoverflow.com/questions/62067617/embed-jitsi-video-conference-into-vuejs-app
  import { mapActions, mapState } from 'vuex'
  import Timer from '@/components/Timer.vue'
  import Card from '@/components/Card.vue'

  export default {
    name: 'Call',
    data() {
      return {
        search: '',
        show: false,
        api: null,
        room: '',
        username: '',
        window: {
          height: 0,
        },
      }
    },
    components: {
      Timer,
      Card,
    },
    computed: {
      ...mapState([
        'call',
        'logged',
        'infoMessages',
        'calldata',
        'card',
        'translations',
      ]),
      checkTime() {
        if (this.calldata) {
          let clas = 0
          var d1 = new Date()
          var d2 = new Date(this.calldata.day + ' ' + this.calldata.time_start)
          var d3 = new Date(this.calldata.day + ' ' + this.calldata.time_end)

          if (d1 >= d2 && d3 > d1) {
            clas = 1
          } else if (d3 <= d1) {
            clas = 2
          }
          return clas
        }
      },
    },
    methods: {
      ...mapActions([
        'setMessage',
        'changeToolSelected',
        'getCallData',
        'getCardCall',
        'resetCall',
      ]),
      showCard() {
        this.show = !this.show
      },
      endTime() {
        this.resetCall()
        window.location.reload()
      },
      handleResize() {
        this.window.height = window.innerHeight
      },
      startConference() {
        var _this = this
        if (this.checkTime == 1) {
          try {
            const domain = 'meet.jit.si'
            var options = {
              roomName: this.room,
              height: this.window.height - 230,
              parentNode: document.querySelector('#meet'),
              interfaceConfigOverwrite: {
                filmStripOnly: false,
                SHOW_JITSI_WATERMARK: false,
              },
              configOverwrite: {
                disableSimulcast: false,
              },
            }

            this.api = new JitsiMeetExternalAPI(domain, options)
            this.api.addEventListener('videoConferenceJoined', () => {
              console.log('Local User Joined')

              _this.api.executeCommand('displayName', _this.username)
            })
          } catch (error) {
            console.error('Failed to load Jitsi API', error)
          }
        }
      },
      openRoom() {
        // verify the JitsiMeetExternalAPI constructor is added to the global..
        if (window.JitsiMeetExternalAPI) {
          var room = this.$route.params.id
          if (room != null || room != '') this.room = room
          this.startConference()
        } else {
          console.log('Jitsi Meet API script not loaded')
        }
      },
      replaceTranslation(value) {
        let result = ''
        switch (value.type) {
          case 'end':
            result = this.translations.time_meeting_end.replace(
              '{var}',
              value.text
            )
            break
          case 'next':
            result = this.translations.time_meeting_next.replace(
              '{var}',
              value.text
            )
            break
        }
        return result
      },
    },

    async created() {
      let ls = JSON.parse(localStorage.getItem('loged'))
      window.addEventListener('resize', this.handleResize)
      await this.handleResize()
      await this.getCallData(this.$route.params.id)
      if (this.calldata) {
        await this.getCardCall(this.calldata.hostguest_code)
      }
      this.username = ls.fullName
      this.openRoom()
    },

    beforeUnmount() {
      if (this.checkTime == 1) {
        this.api.dispose()
      }
    },
    unmounted() {
      window.removeEventListener('resize', this.handleResize)
    },
    watch: {
      $route(to, from) {
        if (to.name == 'Call') {
          window.location.reload()
          window.addEventListener('resize', this.handleResize)
          this.handleResize()
          this.getCallData(this.$route.params.id)
          if (this.calldata) {
            this.getCardCall(this.calldata.hostguest_code)
          }
        }
      },
    },
  }
</script>
